.App {
  text-align: center; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid {
  flex-grow: 1;
}

.kep-login-facebook.metro { 
  width: 60px;
  height: 50px;  
  border-radius: 30%;
  background: #0000;
  border:0px transparent;  
  text-align: center;
  margin-top: 20px;
  color: #4c69ba;
  display: inline-block;
}

.btnGoogle {
  margin: 20px;
  width: 45px;
  height: 50px;  
  border-radius: 30% !important;
  background: #0000;
  text-align: center;
}

.btnGoogle:hover {
  cursor: pointer;
}

.target {
  margin-top: 40;
}

.bigButton{
font-size: 1rem;
background-color: #FF8300;
color: white ;
border-radius: 40px;
text-transform: none;
height: 40px;
width: 17vw;
max-width: 500px;
min-width: 200px;
border-color: transparent
}

.bigButton:hover {
  color: #FF8300;
  background-color: transparent;
  border: 2px solid #FF8300;
  transition-duration: 0.5s;
  cursor: pointer;
}

.exitButton{
  font-size: 1rem;
  background-color: gray;
  color: white ;
  border-radius: 40px;
  text-transform: none;
  height: 40px;
  width: 200px;
  min-width: 200px;
  border-color: transparent;
  margin-left: -30px;
  }
  
.exitButton:hover {
    color: gray;
    background-color: transparent;
    border: 2px solid gray;
    transition-duration: 0.5s;
    cursor: pointer;
  }

.addFilters{
  font-size: 1rem;
  background-color: #0A50C7;
  color: white ;
  border-radius: 40px;
  text-transform: none;
  height: 40px;
  width: 120px;
  min-width: 100px;
  border-color: transparent;
  }
  
  .addFilters:hover {
    color: #0A50C7;
    background-color: transparent;
    border: 2px solid #0A50C7;
    transition-duration: 0.5s;
    cursor: pointer;
  }

.carousel-root {
  width: '100%';
}
.carousel.carousel-slider .control-arrow {
  color: #0492C1 !important

}


.carouselLanding {
  width: 95vw
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  margin-top: 10px;
  width: 30vw;
  height: 40px;
  background-color: white;
  border-radius: 0 20px 20px 20px;
  padding: 5px;
  padding-left: 10px;
}


.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 27vw;
  margin-left:  20px;
  padding: 5px;
  border: 1px solid #fff;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07), 
  0 4px 8px rgba(0,0,0,0.07), 
  0 8px 16px rgba(0,0,0,0.07),
  0 16px 32px rgba(0,0,0,0.07), 
  0 32px 64px rgba(0,0,0,0.07);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}



